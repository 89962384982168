import * as React from "react"
const TeacherSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    fontWeight={800}
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#2C2669"
        fillRule="evenodd"
        d="M7.183 3.016a3.983 3.983 0 1 1 5.634 5.634 3.983 3.983 0 0 1-5.634-5.634ZM10 3.15a2.683 2.683 0 1 0 0 5.366 2.683 2.683 0 0 0 0-5.366Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillOpacity={0.9}
        fillRule="evenodd"
        d="M7.183 3.016a3.983 3.983 0 1 1 5.634 5.634 3.983 3.983 0 0 1-5.634-5.634ZM10 3.15a2.683 2.683 0 1 0 0 5.366 2.683 2.683 0 0 0 0-5.366Z"
        clipRule="evenodd"
      />
      <path
        fill="#2C2669"
        fillRule="evenodd"
        d="M8.333 13.15a2.683 2.683 0 0 0-2.683 2.683V17.5a.65.65 0 1 1-1.3 0v-1.667a3.983 3.983 0 0 1 3.983-3.983h.417a.65.65 0 1 1 0 1.3h-.417Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillOpacity={0.9}
        fillRule="evenodd"
        d="M8.333 13.15a2.683 2.683 0 0 0-2.683 2.683V17.5a.65.65 0 1 1-1.3 0v-1.667a3.983 3.983 0 0 1 3.983-3.983h.417a.65.65 0 1 1 0 1.3h-.417Z"
        clipRule="evenodd"
      />
      <path
        stroke="#2C2669"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m14.832 17.348-1.81.949a.326.326 0 0 1-.473-.342l.345-2.01-1.464-1.422a.323.323 0 0 1 .181-.554l2.023-.293.905-1.828a.327.327 0 0 1 .585 0l.905 1.828 2.024.293a.325.325 0 0 1 .18.554l-1.464 1.423.345 2.008a.325.325 0 0 1-.472.343l-1.81-.949Z"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity={0.9}
        strokeWidth={1.3}
        d="m14.832 17.348-1.81.949a.326.326 0 0 1-.473-.342l.345-2.01-1.464-1.422a.323.323 0 0 1 .181-.554l2.023-.293.905-1.828a.327.327 0 0 1 .585 0l.905 1.828 2.024.293a.325.325 0 0 1 .18.554l-1.464 1.423.345 2.008a.325.325 0 0 1-.472.343l-1.81-.949Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default TeacherSvg
