import * as React from "react"
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props} width={20} height={20}>
    <path
      fill="#2C2669"
      fillRule="evenodd"
      d="M6.662 3.542h.005c.583 0 1.128.267 1.501.715l3.638 4.379a2.162 2.162 0 0 1 0 2.729l-.004.005-3.632 4.37a1.954 1.954 0 0 1-1.499.719h-.004a1.954 1.954 0 0 1-1.502-.716l-3.638-4.378a2.162 2.162 0 0 1 0-2.73l.004-.004L5.163 4.26a1.954 1.954 0 0 1 1.499-.718Zm.007 1.25a.704.704 0 0 0-.539.26l-.004.004-3.631 4.371a.912.912 0 0 0 0 1.147l3.631 4.37a.705.705 0 0 0 .538.265.704.704 0 0 0 .539-.26l.004-.005 3.632-4.37a.912.912 0 0 0 0-1.147l-3.632-4.37a.705.705 0 0 0-.538-.265Z"
      clipRule="evenodd"
      strokeWidth={1.9}
      fontSize={30}
    />
    <path
      fill="#000"
      fillOpacity={0.9}
      fillRule="evenodd"
      d="M6.662 3.542h.005c.583 0 1.128.267 1.501.715l3.638 4.379a2.162 2.162 0 0 1 0 2.729l-.004.005-3.632 4.37a1.954 1.954 0 0 1-1.499.719h-.004a1.954 1.954 0 0 1-1.502-.716l-3.638-4.378a2.162 2.162 0 0 1 0-2.73l.004-.004L5.163 4.26a1.954 1.954 0 0 1 1.499-.718Zm.007 1.25a.704.704 0 0 0-.539.26l-.004.004-3.631 4.371a.912.912 0 0 0 0 1.147l3.631 4.37a.705.705 0 0 0 .538.265.704.704 0 0 0 .539-.26l.004-.005 3.632-4.37a.912.912 0 0 0 0-1.147l-3.632-4.37a.705.705 0 0 0-.538-.265Z"
      clipRule="evenodd"
    />
    <path
      fill="#2C2669"
      fillRule="evenodd"
      d="M13.78 3.676a.625.625 0 0 1 .878.105l3.819 4.86a2.161 2.161 0 0 1 0 2.72l-3.819 4.859a.625.625 0 0 1-.983-.772l3.829-4.872a.911.911 0 0 0 0-1.151l-.007-.008-3.822-4.864a.625.625 0 0 1 .106-.877Z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      fillOpacity={0.9}
      fillRule="evenodd"
      d="M13.78 3.676a.625.625 0 0 1 .878.105l3.819 4.86a2.161 2.161 0 0 1 0 2.72l-3.819 4.859a.625.625 0 0 1-.983-.772l3.829-4.872a.911.911 0 0 0 0-1.151l-.007-.008-3.822-4.864a.625.625 0 0 1 .106-.877Z"
      clipRule="evenodd"
    />
    <path
      fill="#2C2669"
      fillRule="evenodd"
      d="M10.447 3.676a.625.625 0 0 1 .878.105l3.818 4.86a2.162 2.162 0 0 1 0 2.72l-3.818 4.859a.625.625 0 1 1-.983-.772l3.828-4.872a.911.911 0 0 0 0-1.151l-.007-.008-3.821-4.864a.625.625 0 0 1 .105-.877Z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      fillOpacity={0.9}
      fillRule="evenodd"
      d="M10.447 3.676a.625.625 0 0 1 .878.105l3.818 4.86a2.162 2.162 0 0 1 0 2.72l-3.818 4.859a.625.625 0 1 1-.983-.772l3.828-4.872a.911.911 0 0 0 0-1.151l-.007-.008-3.821-4.864a.625.625 0 0 1 .105-.877Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent