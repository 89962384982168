import * as React from "react"
const SettingSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
    style={{marginRight:'10px'}}
  >
    <path
      fill="#2C2669"
      fillRule="evenodd"
      d="M10.789 3.745c-.201-.827-1.377-.827-1.577 0A2.06 2.06 0 0 1 6.135 5.02c-.727-.443-1.559.39-1.116 1.116a2.062 2.062 0 0 1-1.275 3.077c-.827.2-.827 1.376 0 1.577a2.062 2.062 0 0 1 1.274 3.075c-.443.727.39 1.559 1.116 1.116a2.062 2.062 0 0 1 3.077 1.275c.2.827 1.376.827 1.577 0a2.062 2.062 0 0 1 3.075-1.274c.727.443 1.559-.39 1.116-1.116a2.062 2.062 0 0 1 1.275-3.076c.827-.201.827-1.377 0-1.577a2.062 2.062 0 0 1-1.274-3.076c.443-.727-.39-1.559-1.116-1.116a2.062 2.062 0 0 1-3.076-1.275ZM7.997 3.45c.51-2.1 3.497-2.1 4.006 0a.81.81 0 0 0 1.212.502c1.845-1.124 3.958.988 2.834 2.834a.811.811 0 0 0 .5 1.21c2.101.51 2.101 3.498 0 4.007a.81.81 0 0 0-.501 1.212c1.124 1.845-.988 3.958-2.834 2.834a.81.81 0 0 0-1.21.5c-.51 2.101-3.498 2.101-4.007 0a.81.81 0 0 0-1.212-.501c-1.845 1.124-3.958-.988-2.834-2.834a.811.811 0 0 0-.5-1.21c-2.101-.51-2.101-3.498 0-4.007a.812.812 0 0 0 .501-1.212C2.828 4.94 4.94 2.827 6.786 3.951a.81.81 0 0 0 1.21-.5"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      fillOpacity={0.2}
      fillRule="evenodd"
      d="M10.789 3.745c-.201-.827-1.377-.827-1.577 0A2.06 2.06 0 0 1 6.135 5.02c-.727-.443-1.559.39-1.116 1.116a2.062 2.062 0 0 1-1.275 3.077c-.827.2-.827 1.376 0 1.577a2.062 2.062 0 0 1 1.274 3.075c-.443.727.39 1.559 1.116 1.116a2.062 2.062 0 0 1 3.077 1.275c.2.827 1.376.827 1.577 0a2.062 2.062 0 0 1 3.075-1.274c.727.443 1.559-.39 1.116-1.116a2.062 2.062 0 0 1 1.275-3.076c.827-.201.827-1.377 0-1.577a2.062 2.062 0 0 1-1.274-3.076c.443-.727-.39-1.559-1.116-1.116a2.062 2.062 0 0 1-3.076-1.275ZM7.997 3.45c.51-2.1 3.497-2.1 4.006 0a.81.81 0 0 0 1.212.502c1.845-1.124 3.958.988 2.834 2.834a.811.811 0 0 0 .5 1.21c2.101.51 2.101 3.498 0 4.007a.81.81 0 0 0-.501 1.212c1.124 1.845-.988 3.958-2.834 2.834a.81.81 0 0 0-1.21.5c-.51 2.101-3.498 2.101-4.007 0a.81.81 0 0 0-1.212-.501c-1.845 1.124-3.958-.988-2.834-2.834a.811.811 0 0 0-.5-1.21c-2.101-.51-2.101-3.498 0-4.007a.812.812 0 0 0 .501-1.212C2.828 4.94 4.94 2.827 6.786 3.951a.81.81 0 0 0 1.21-.5"
      clipRule="evenodd"
    />
    <path
      fill="#2C2669"
      fillRule="evenodd"
      d="M7.582 7.582a3.125 3.125 0 1 1 4.42 4.42 3.125 3.125 0 0 1-4.42-4.42Zm2.21.335a1.875 1.875 0 1 0 0 3.75 1.875 1.875 0 0 0 0-3.75Z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      fillOpacity={0.2}
      fillRule="evenodd"
      d="M7.582 7.582a3.125 3.125 0 1 1 4.42 4.42 3.125 3.125 0 0 1-4.42-4.42Zm2.21.335a1.875 1.875 0 1 0 0 3.75 1.875 1.875 0 0 0 0-3.75Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SettingSvg
