import React from 'react'
import { ArchiveTabs } from '../groups/tabs'

const Archive = () => {
  return (
    <div style={{marginLeft:'330px'}}>
      <ArchiveTabs/>
    </div>
  )
}

export default Archive