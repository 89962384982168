import * as React from "react"
const SearchIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#BFBAE3"
      d="m15.026 13.848 3.569 3.569-1.179 1.178-3.569-3.569a7.468 7.468 0 0 1-4.68 1.641c-4.14 0-7.5-3.36-7.5-7.5 0-4.14 3.36-7.5 7.5-7.5 4.14 0 7.5 3.36 7.5 7.5 0 1.77-.614 3.397-1.641 4.68Zm-1.672-.619A5.814 5.814 0 0 0 15 9.167a5.832 5.832 0 0 0-5.833-5.833 5.832 5.832 0 0 0-5.834 5.833A5.832 5.832 0 0 0 9.167 15c1.58 0 3.012-.627 4.062-1.646l.125-.125Z"
    />
  </svg>
)
export default SearchIcon
