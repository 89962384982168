import * as React from "react"
const DeleteIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#2C2669"
      d="M5.308 5.867c.297 0 .538.238.538.533v6.4a.536.536 0 0 1-.538.533.536.536 0 0 1-.539-.533V6.4c0-.295.241-.533.539-.533Z"
    />
    <path
      fill="#000"
      fillOpacity={0.2}
      d="M5.308 5.867c.297 0 .538.238.538.533v6.4a.536.536 0 0 1-.538.533.536.536 0 0 1-.539-.533V6.4c0-.295.241-.533.539-.533Z"
    />
    <path
      fill="#2C2669"
      d="M8 5.867c.297 0 .538.238.538.533v6.4a.536.536 0 0 1-.538.533.536.536 0 0 1-.538-.533V6.4c0-.295.24-.533.538-.533Z"
    />
    <path
      fill="#000"
      fillOpacity={0.2}
      d="M8 5.867c.297 0 .538.238.538.533v6.4a.536.536 0 0 1-.538.533.536.536 0 0 1-.538-.533V6.4c0-.295.24-.533.538-.533Z"
    />
    <path
      fill="#2C2669"
      d="M11.23 6.4a.536.536 0 0 0-.538-.533.536.536 0 0 0-.538.533v6.4c0 .295.24.533.538.533a.536.536 0 0 0 .539-.533V6.4Z"
    />
    <path
      fill="#000"
      fillOpacity={0.2}
      d="M11.23 6.4a.536.536 0 0 0-.538-.533.536.536 0 0 0-.538.533v6.4c0 .295.24.533.538.533a.536.536 0 0 0 .539-.533V6.4Z"
    />
    <path
      fill="#2C2669"
      fillRule="evenodd"
      d="M15 3.2c0 .59-.482 1.067-1.077 1.067h-.538v9.6A2.144 2.144 0 0 1 11.23 16H4.769a2.144 2.144 0 0 1-2.154-2.133v-9.6h-.538A1.072 1.072 0 0 1 1 3.2V2.133c0-.589.482-1.066 1.077-1.066h3.77C5.846.477 6.327 0 6.922 0h2.154c.595 0 1.077.478 1.077 1.067h3.77c.594 0 1.076.477 1.076 1.066V3.2ZM3.82 4.267l-.128.063v9.537c0 .589.482 1.066 1.077 1.066h6.462c.595 0 1.077-.477 1.077-1.066V4.33l-.127-.063H3.819ZM2.076 3.2V2.133h11.846V3.2H2.077Z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      fillOpacity={0.2}
      fillRule="evenodd"
      d="M15 3.2c0 .59-.482 1.067-1.077 1.067h-.538v9.6A2.144 2.144 0 0 1 11.23 16H4.769a2.144 2.144 0 0 1-2.154-2.133v-9.6h-.538A1.072 1.072 0 0 1 1 3.2V2.133c0-.589.482-1.066 1.077-1.066h3.77C5.846.477 6.327 0 6.922 0h2.154c.595 0 1.077.478 1.077 1.067h3.77c.594 0 1.076.477 1.076 1.066V3.2ZM3.82 4.267l-.128.063v9.537c0 .589.482 1.066 1.077 1.066h6.462c.595 0 1.077-.477 1.077-1.066V4.33l-.127-.063H3.819ZM2.076 3.2V2.133h11.846V3.2H2.077Z"
      clipRule="evenodd"
    />
  </svg>
)
export default DeleteIcon
