import * as React from "react"
const InfoIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#A098D5"
      d="M9 16.5a7.5 7.5 0 1 1 0-15 7.5 7.5 0 0 1 0 15ZM9 15A6 6 0 1 0 9 3a6 6 0 0 0 0 12Zm.75-7.125v3.375h.75v1.5h-3v-1.5h.75V9.375H7.5v-1.5h2.25ZM10.125 6a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0Z"
    />
  </svg>
)
export default InfoIcon
