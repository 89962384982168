import Profile from '..//assets/prof.png';

export const teachers = [
    {
      name: 'John Doe',
      position: 'Math Teacher',
      phoneNumber: '+1 123-456-7890',
      telegramUsername: '@john_doe',
      homeLocation: 'New York, USA',
      salary:'21 500.45',
      group:'2-15',
      percentage:'20%',
      image: Profile,
      id:'1',
    },
    {
      name: 'Jane Smith',
      position: 'English Teacher',
      phoneNumber: '+1 987-654-3210',
      telegramUsername: '@jane_smith',
      homeLocation: 'Los Angeles, USA',
      salary:'21 500.45',
      group:'2-15',
      percentage:'20%',
      image: Profile,
      id:'2',
    },
    {
        name: 'Alice Johnson',
        position: 'Math Teacher',
        phoneNumber: '+1 123-456-7890',
        telegramUsername: '@alice_math',
        homeLocation: 'New York, USA',
        salary:'21 500.45',
        group:'2-15',
        percentage:'20%',
        image: Profile,
        id:'3',
      },
      {
        name: 'Bob Smith',
        position: 'English Teacher',
        phoneNumber: '+1 987-654-3210',
        telegramUsername: '@bob_english',
        homeLocation: 'Los Angeles, USA',
        salary:'21 500.45',
        group:'2-15',
        percentage:'20%',
        image: Profile,
        id:'4',
      },
      {
        name: 'Carol Lee',
        position: 'Science Teacher',
        phoneNumber: '+1 555-123-4567',
        telegramUsername: '@carol_science',
        homeLocation: 'Chicago, USA',
        salary:'21 500.45',
        group:'2-15',
        percentage:'20%',
        image: Profile,
        id:'5',
      },
      {
        name: 'Lola Muhamedova',
        position: 'Math Teacher',
        phoneNumber: '+1 123-456-7890',
        telegramUsername: '@lola_math',
        homeLocation: 'New York, USA',
        salary:'21 500.45',
        group:'2-15',
        percentage:'20%',
        image: Profile,
        id:'6',
      },
      {
        name: 'Ali Shoxasanov',
        position: 'English Teacher',
        phoneNumber: '+1 987-654-3210',
        telegramUsername: '@ali_english',
        homeLocation: 'Los Angeles, USA',
        salary:'21 500.45',
        group:'2-15',
        percentage:'20%',
        image: Profile,
        id:'7',
      },
      {
        name: 'Jack Daniel',
        position: 'Science Teacher',
        phoneNumber: '+1 555-123-4567',
        telegramUsername: '@jack_science',
        homeLocation: 'Chicago, USA',
        salary:'21 500.45',
        group:'2-15',
        percentage:'20%',
        image: Profile,
        id:'8',
      },
      {
        name: 'McGregor Jose',
        position: 'History Teacher',
        phoneNumber: '+1 111-222-3333',
        telegramUsername: '@mcgregor_history',
        homeLocation: 'London, UK',
        salary:'21 500.45',
        group:'2-15',
        percentage:'20%',
        image: Profile,
        id:'9',
      },
      {
        name: 'Susan Dickenson',
        position: 'Art Teacher',
        phoneNumber: '+1 444-555-6666',
        telegramUsername: '@susan_art',
        homeLocation: 'Paris, France',
        salary:'21 500.45',
        group:'2-15',
        percentage:'20%',
        image: Profile,
        id:'10',
      }
  ];
  
  