import * as React from "react"
const LeadsSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
      <path
        fill="#2C2669"
        d="M4.683 3.016a3.983 3.983 0 1 1 5.634 5.634 3.983 3.983 0 0 1-5.634-5.634ZM7.5 3.15a2.683 2.683 0 1 0 0 5.366 2.683 2.683 0 0 0 0-5.366Z"
      />
      <path
        fill="#000"
        fillOpacity={0.9}
        d="M4.683 3.016a3.983 3.983 0 1 1 5.634 5.634 3.983 3.983 0 0 1-5.634-5.634ZM7.5 3.15a2.683 2.683 0 1 0 0 5.366 2.683 2.683 0 0 0 0-5.366Z"
      />
      <path
        fill="#2C2669"
        d="M5.833 13.15a2.683 2.683 0 0 0-2.683 2.683V17.5a.65.65 0 1 1-1.3 0v-1.667a3.983 3.983 0 0 1 3.983-3.983h3.334c.955 0 1.832.337 2.519.897a.65.65 0 1 1-.822 1.007 2.673 2.673 0 0 0-1.697-.604H5.833Z"
      />
      <path
        fill="#000"
        fillOpacity={0.9}
        d="M5.833 13.15a2.683 2.683 0 0 0-2.683 2.683V17.5a.65.65 0 1 1-1.3 0v-1.667a3.983 3.983 0 0 1 3.983-3.983h3.334c.955 0 1.832.337 2.519.897a.65.65 0 1 1-.822 1.007 2.673 2.673 0 0 0-1.697-.604H5.833Z"
      />
      <path
        fill="#2C2669"
        d="M12.704 2.447a.65.65 0 0 1 .79-.469 3.983 3.983 0 0 1 0 7.718.65.65 0 1 1-.322-1.26 2.684 2.684 0 0 0 0-5.198.65.65 0 0 1-.468-.791Z"
      />
      <path
        fill="#000"
        fillOpacity={0.9}
        d="M12.704 2.447a.65.65 0 0 1 .79-.469 3.983 3.983 0 0 1 0 7.718.65.65 0 1 1-.322-1.26 2.684 2.684 0 0 0 0-5.198.65.65 0 0 1-.468-.791Z"
      />
      <path
        fill="#2C2669"
        d="M12.683 15.834a.65.65 0 0 1 .65-.65h5a.65.65 0 1 1 0 1.3h-5a.65.65 0 0 1-.65-.65Z"
      />
      <path
        fill="#000"
        fillOpacity={0.9}
        d="M12.683 15.834a.65.65 0 0 1 .65-.65h5a.65.65 0 1 1 0 1.3h-5a.65.65 0 0 1-.65-.65Z"
      />
      <path
        fill="#2C2669"
        d="M15.833 12.684a.65.65 0 0 1 .65.65v5a.65.65 0 1 1-1.3 0v-5a.65.65 0 0 1 .65-.65Z"
      />
      <path
        fill="#000"
        fillOpacity={0.9}
        d="M15.833 12.684a.65.65 0 0 1 .65.65v5a.65.65 0 1 1-1.3 0v-5a.65.65 0 0 1 .65-.65Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default LeadsSvg
