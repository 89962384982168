import * as React from "react"
const EditProfile = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={16}
    fill="none"
    {...props}
    viewBox="0 0 15 10"
  >
    <path
      fill="#ffffff"
      fillRule="evenodd"
      d="M9.11.11a.375.375 0 0 1 .53 0l2.25 2.25a.375.375 0 0 1 0 .53l-7.5 7.5a.376.376 0 0 1-.126.083l-3.75 1.5a.375.375 0 0 1-.487-.487l1.5-3.75a.375.375 0 0 1 .083-.126l7.5-7.5Zm-.705 1.765 1.72 1.72.97-.97-1.72-1.72-.97.97Zm1.19 2.25-1.72-1.72L3 7.28v.22h.375c.207 0 .375.168.375.375v.375h.375c.207 0 .375.168.375.375V9h.22l4.875-4.875ZM2.274 8.007l-.08.079-1.146 2.866 2.866-1.147.08-.079a.375.375 0 0 1-.244-.351V9h-.375A.375.375 0 0 1 3 8.625V8.25h-.375a.375.375 0 0 1-.351-.243Z"
      clipRule="evenodd"
    />
  </svg>
)
export default EditProfile
