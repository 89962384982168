import * as React from "react"
const MessageIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#2C2669"
      d="m2.305 14.404.141.049-.141-.049-.004.011a10.596 10.596 0 0 1-.24.628c-.12.284.11.62.435.567a21.951 21.951 0 0 0 .698-.125l.02-.004c1.786-.354 2.904-.82 3.455-1.093A9.217 9.217 0 0 0 9 14.685c4.482 0 8.15-3.183 8.15-7.15 0-3.967-3.668-7.15-8.15-7.15S.85 3.568.85 7.535c0 1.78.742 3.406 1.963 4.655a10.377 10.377 0 0 1-.508 2.214Zm1.81-2.16a1.15 1.15 0 0 0-.33-.921C2.747 10.284 2.15 8.959 2.15 7.535c0-3.093 2.919-5.85 6.85-5.85 3.931 0 6.85 2.757 6.85 5.85 0 3.092-2.919 5.85-6.85 5.85a7.91 7.91 0 0 1-2.05-.267 1.15 1.15 0 0 0-.817.085c-.353.179-1.116.516-2.353.82.157-.577.278-1.188.335-1.779Z"
    />
    <path
      fill="#000"
      fillOpacity={0.2}
      d="m2.305 14.404.141.049-.141-.049-.004.011a10.596 10.596 0 0 1-.24.628c-.12.284.11.62.435.567a21.951 21.951 0 0 0 .698-.125l.02-.004c1.786-.354 2.904-.82 3.455-1.093A9.217 9.217 0 0 0 9 14.685c4.482 0 8.15-3.183 8.15-7.15 0-3.967-3.668-7.15-8.15-7.15S.85 3.568.85 7.535c0 1.78.742 3.406 1.963 4.655a10.377 10.377 0 0 1-.508 2.214Zm1.81-2.16a1.15 1.15 0 0 0-.33-.921C2.747 10.284 2.15 8.959 2.15 7.535c0-3.093 2.919-5.85 6.85-5.85 3.931 0 6.85 2.757 6.85 5.85 0 3.092-2.919 5.85-6.85 5.85a7.91 7.91 0 0 1-2.05-.267 1.15 1.15 0 0 0-.817.085c-.353.179-1.116.516-2.353.82.157-.577.278-1.188.335-1.779Z"
    />
    <path
      stroke="#2C2669"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.3}
      d="m2.305 14.404.141.049-.141-.049-.004.011a10.596 10.596 0 0 1-.24.628c-.12.284.11.62.435.567a21.951 21.951 0 0 0 .698-.125l.02-.004c1.786-.354 2.904-.82 3.455-1.093A9.217 9.217 0 0 0 9 14.685c4.482 0 8.15-3.183 8.15-7.15 0-3.967-3.668-7.15-8.15-7.15S.85 3.568.85 7.535c0 1.78.742 3.406 1.963 4.655a10.377 10.377 0 0 1-.508 2.214Zm1.81-2.16a1.15 1.15 0 0 0-.33-.921C2.747 10.284 2.15 8.959 2.15 7.535c0-3.093 2.919-5.85 6.85-5.85 3.931 0 6.85 2.757 6.85 5.85 0 3.092-2.919 5.85-6.85 5.85a7.91 7.91 0 0 1-2.05-.267 1.15 1.15 0 0 0-.817.085c-.353.179-1.116.516-2.353.82.157-.577.278-1.188.335-1.779Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeOpacity={0.2}
      strokeWidth={0.3}
      d="m2.305 14.404.141.049-.141-.049-.004.011a10.596 10.596 0 0 1-.24.628c-.12.284.11.62.435.567a21.951 21.951 0 0 0 .698-.125l.02-.004c1.786-.354 2.904-.82 3.455-1.093A9.217 9.217 0 0 0 9 14.685c4.482 0 8.15-3.183 8.15-7.15 0-3.967-3.668-7.15-8.15-7.15S.85 3.568.85 7.535c0 1.78.742 3.406 1.963 4.655a10.377 10.377 0 0 1-.508 2.214Zm1.81-2.16a1.15 1.15 0 0 0-.33-.921C2.747 10.284 2.15 8.959 2.15 7.535c0-3.093 2.919-5.85 6.85-5.85 3.931 0 6.85 2.757 6.85 5.85 0 3.092-2.919 5.85-6.85 5.85a7.91 7.91 0 0 1-2.05-.267 1.15 1.15 0 0 0-.817.085c-.353.179-1.116.516-2.353.82.157-.577.278-1.188.335-1.779Z"
    />
  </svg>
)
export default MessageIcon
