import React from 'react'
import { CEOTab } from '../groups/tabs'

const CEO = () => {
  return (
    <div style={{marginLeft:'330px'}}>
        <CEOTab/>
    </div>
  )
}

export default CEO