import * as React from "react"
const StudentSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={30}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#2C2669"
      d="M4.667 9.222 2 7.667 10 3l8 4.667v5.666h-1V8.167l-1.667 1.055v4.452l-.148.184A6.655 6.655 0 0 1 10 16.333a6.655 6.655 0 0 1-5.185-2.475l-.148-.184V9.222Zm.833.445v3.5c1.5 1.5 2.907 2 4.5 2s2.5 0 4.5-2v-3.5L10 12.333 5.5 9.667Zm-1.5-2 6 3.5 6-3.5-6-3.5-6 3.5Z"
    />
    <path
      fill="#000"
      fillOpacity={1}
      d="M4.667 9.222 2 7.667 10 3l8 4.667v5.666h-1V8.167l-1.667 1.055v4.452l-.148.184A6.655 6.655 0 0 1 10 16.333a6.655 6.655 0 0 1-5.185-2.475l-.148-.184V9.222Zm.833.445v3.5c1.5 1.5 2.907 2 4.5 2s2.5 0 4.5-2v-3.5L10 12.333 5.5 9.667Zm-1.5-2 6 3.5 6-3.5-6-3.5-6 3.5Z"
    />
  </svg>
)
export default StudentSvg
