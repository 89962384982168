import * as React from "react"
const BudgetSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 15 20"
    {...props}
  >
    <g fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
      <path
        fill="#2C2669"
        d="M6.939 2.609a8 8 0 1 1 6.123 14.782A8 8 0 0 1 6.939 2.61ZM10 3a7 7 0 1 0 0 14 7 7 0 0 0 0-14Z"
      />
      <path
        fill="#000"
        fillOpacity={0.9}
        d="M6.939 2.609a8 8 0 1 1 6.123 14.782A8 8 0 0 1 6.939 2.61ZM10 3a7 7 0 1 0 0 14 7 7 0 0 0 0-14Z"
      />
      <path
        fill="#2C2669"
        d="M10.825 6.167a2.166 2.166 0 0 1 1.942 1.084.5.5 0 0 1-.867.499 1.167 1.167 0 0 0-1.05-.583H9.167a1.167 1.167 0 0 0 0 2.333h1.666a2.167 2.167 0 1 1 0 4.334H9.175a2.166 2.166 0 0 1-1.942-1.084.5.5 0 1 1 .867-.5 1.167 1.167 0 0 0 1.05.584H10.833a1.167 1.167 0 1 0 0-2.334H9.167a2.167 2.167 0 0 1 0-4.333h1.659Z"
      />
      <path
        fill="#000"
        fillOpacity={0.9}
        d="M10.825 6.167a2.166 2.166 0 0 1 1.942 1.084.5.5 0 0 1-.867.499 1.167 1.167 0 0 0-1.05-.583H9.167a1.167 1.167 0 0 0 0 2.333h1.666a2.167 2.167 0 1 1 0 4.334H9.175a2.166 2.166 0 0 1-1.942-1.084.5.5 0 1 1 .867-.5 1.167 1.167 0 0 0 1.05.584H10.833a1.167 1.167 0 1 0 0-2.334H9.167a2.167 2.167 0 0 1 0-4.333h1.659Z"
      />
      <path
        fill="#2C2669"
        d="M10 5.333a.5.5 0 0 1 .5.5v8.333a.5.5 0 1 1-1 0V5.833a.5.5 0 0 1 .5-.5Z"
      />
      <path
        fill="#000"
        fillOpacity={0.9}
        d="M10 5.333a.5.5 0 0 1 .5.5v8.333a.5.5 0 1 1-1 0V5.833a.5.5 0 0 1 .5-.5Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default BudgetSvg
