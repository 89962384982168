import React from 'react'
import {HistoryWrapper} from '../styles'

const History = () => {
  return (
    <HistoryWrapper>
      <h3>Status changed</h3>
      <p>Name of Student / Teacher - Phone number</p>
      <p>Group name: <span>Group name</span></p>
      <p>Group: <span>Group number</span></p>
      <p>Current status: <b>Active</b></p>
      <p>Previous status: <b>Active</b></p>
      <h5></h5>
    </HistoryWrapper>
  )
}

export default History