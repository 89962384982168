import * as React from "react"
const ResetIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#BFBAE3"
      d="M10 3.334a6.655 6.655 0 0 0-5.298 2.619l1.965 1.964h-5v-5l1.849 1.849A8.333 8.333 0 0 1 18.334 10h-1.667A6.667 6.667 0 0 0 10 3.334ZM3.333 10a6.667 6.667 0 0 0 11.965 4.048l-1.965-1.964h5v5l-1.848-1.85A8.333 8.333 0 0 1 1.667 10h1.666Z"
    />
  </svg>
)
export default ResetIcon
