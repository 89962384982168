import React from 'react'
import { OfficeTab } from '../groups/tabs'

const Office = () => {
  return (
    <div style={{marginLeft:'330px'}}>
        <OfficeTab/>
    </div>
  )
}

export default Office